@import (inline) '../css/normalize-8.0.0.css';
@import (inline) '../css/fontawesome-5.10.2.css';
@import '_mixins/reset';
@import '_mixins/colors';
@import '_mixins/constants';
@import '_mixins/fonts';
@import '_mixins/mediaQueries';
@import '_mixins/animations';
@import '_components/burger';
@import '_components/button';
@import '_components/columns';
@import '_components/font';
@import '_components/footer';
@import '_components/form';
@import '_components/slider';
@import '_components/header';
@import '_components/image';
@import '_components/map';
@import '_components/medienmonster';
@import '_components/menu';
@import '_components/previewBox';
@import '_components/slick';
@import '_components/text';
@import '_components/video';
@import '_components/ad';
@import '_components/filter';
@import '_components/loader';
@import '_components/select';

*, *:before, *:after {
    box-sizing: inherit;
}
html, .body {
    box-sizing: border-box;
    height: 100%;
}
.body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Barlow Semi Condensed, Arial, Helvetica, sans-serif;
    font-size: 1.25em;
    line-height: 1.4em;
}

h1, .h1 {
    font-size: 1.8em;
    line-height: 1.2em;
    font-weight: 500;
}
h2 {
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: 500;
}
a {
    color: @red;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
strong {
    font-weight: 700;
}
ul, ol {
    padding-left: 20px;
}

img {
    width: 100%;
    height: auto;
}

.main {
    width: 100%;
    max-width: (@grid-width + @spacing * 2);
    padding: 0 @spacing;
    margin: 0 auto;
}
.logo {
    display: block;
    max-width: 300px;
    @media @umd {
        min-width: 300px;
        max-width: 25%;
    }
    @media @uxl {
        min-width: 338px;
    }
    width: 100%;
    &.logo--footer {
        min-width: unset;
        max-width: 215px;
        margin-top: (-@spacing);
        margin-bottom: @spacing;
    }
}
.logo__image {
    display: block;
}

.content-wrap {
    &.content-wrap--top {
        margin-top: (@spacing * 4.5);
        @media @umd {
            margin-left: 30%;
        }
    }
    &.content-wrap--bottom {
        margin-top: (@spacing * 8);
    }
}

.mb-0 {
    margin-bottom: 0;
}
.mb-1 {
    margin-bottom: (@spacing / 2);
}
.mb-2 {
    margin-bottom: @spacing;
}

.slogan {
    background: @red;
    width: 100%;
    text-transform: uppercase;
    padding: @spacing;
    border-bottom: @black solid @spacing;
    @media @umd {
        display: none;
    }
    .slogan__text {
        font-family: Barlow Condensed, Arial, Helvetica, sans-serif;
        font-size: 1.6em;
        line-height: 1.4em;
        font-weight: 700;
        color: @white;
        margin: 0;
    }
}

// used to trick the gulp css compiler
.zzz {
    z-index: 1;
}