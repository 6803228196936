.mmmobile-select {
    background: @white;
    color: @dark-grey;
    padding: (@spacing / 4);
    margin-right: @spacing;
    border: 2px solid @light-grey;
    min-width: 250px;
    cursor: pointer;
    @media @usm {
        margin-left: (@spacing / 2);
    }
    &:last-of-type {
        margin-right: 0;
    }
}
.mmmobile-select__overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    cursor: default;
    &.mmmobile-select__overlay--open {
        display: block;
    }
}
.mmmobile-select__arrow {
    position: absolute;
    max-width: 0.8em;
    top: 50%;
    right: (@spacing / 4);
    transform: translateY(-50%);
    transition: transform ease 300ms;
    &.mmmobile-select__arrow--active {
        transform: translateY(-50%) rotate(180deg);
    }
}
.mmmobile-select__options {
    position: absolute;
    background: @white;
    top: 100%;
    right: -2px;
    left: -2px;
    border: 2px solid @light-grey;
    z-index: 1001;
    cursor: pointer;
}
.mmmobile-select__option {
    display: block;
    padding: (@spacing / 4);
    &.mmmobile-select__option--selected {
        background: @light-grey;
    }
}