.nav {
    &.nav--main {
    }
    &.nav--mobile {
    }
    &.nav--lang {
        display: flex;
        align-items: baseline;
    }
}
.nav__icon {
    margin-right: (@spacing / 4);
    font-size: .8em;
}

.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    @media @uxxl {
        margin-bottom: 16px;
    }
    &.menu--lvl1 {
        display: none;
        @media @uxxl {
            display: flex;
        }
    }
    &.menu--lvl2 {
        position: absolute;
        display: none;
        z-index: 3;
        background: @white;
    }
    &.menu--mobile {
        display: block;
        position: relative;
        &.menu--lvl2 {
            transition: max-height 700ms ease;
            max-height: 0px;
            overflow: hidden;
            margin-bottom: 0;
        }
        &.menu--open {
            max-height: 200px;
        }
    }
    &.menu--footer {
        display: block;
    }
    &.menu--lang {
        margin-bottom: 0;
    }
}
.menu__item {
    position: relative;
    padding: 0 3px;
    margin-right: (@spacing - 6);
    &.menu__item--lvl1 {
        &:hover {
            .menu--lvl2 {
                display: block;
            }
        }
        &.menu__item--active {
            &:not(&.menu__item--menu) {
                &:before {
                    content: "";
                    position: absolute;
                    top: -5px;
                    right: 0;
                    left: 0;
                    height: 3px;
                    background: @red;
                }
            }
        }
    }
    &:last-of-type {
        margin-right: 0;
    }
    &.menu__item--mobile {
        padding: 0;
        margin: 0;
        &.menu__item--lvl1 {
            border: 2px solid #f2f2f2;
            margin-bottom: (@spacing / 2);
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &.menu__item--active {
            background: #f2f2f2;
        }
    }
    &.menu__item--footer {
        padding: 0;
    }
}
.menu__link {
    display: block;
    font-family: Barlow Semi Condensed, Arial, Helvetica, sans-serif;
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.2em;
    color: @black;
    text-decoration: none;
    &.menu__link--mobile {
        display: inline-block;
        width: 90%;
        font-size: 1em;
        line-height: 1.2em;
        padding: 5px;
    }
    &.menu__link--footer {
        font-size: 1em;
    }
    &.menu__link--lang {
        display: block;
    }
}
.menu__submenu {
    display: inline-block;
    text-align: center;
    width: 10%;
    cursor: pointer;
    transition: transform 300ms ease;
    &.menu__submenu--open {
        transform: rotate(180deg)
    }
}

.menu-toggle {
    display: none;
    &:checked {
        ~ .header>.header__menus>.nav--main>.burger {
            .burger__line {
                &:nth-child(1) {
                    transform: translateX(0) rotate(45deg);
                }
                &:nth-child(2) {
                    transform: translateX(0) scale(0);
                }
                &:nth-child(3) {
                    transform: translateX(0) rotate(-45deg);
                }
            }
        }
        ~ .header>.header__mobile-menu> {
            display: block;
        }
    }
}