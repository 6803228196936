@font-face {
    font-family: 'Barlow Semi Condensed';
    font-display: swap;
    font-weight: 400;
    src: url('https://cdn.mmsrv.de/fonts/BarlowSemiCondensed/Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    font-display: swap;
    font-weight: 500;
    src: url('https://cdn.mmsrv.de/fonts/BarlowSemiCondensed/Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    font-display: swap;
    font-weight: 700;
    src: url('https://cdn.mmsrv.de/fonts/BarlowSemiCondensed/Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Barlow Condensed';
    font-display: swap;
    font-weight: 400;
    src: url('https://cdn.mmsrv.de/fonts/BarlowCondensed/Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Barlow Condensed';
    font-display: swap;
    font-weight: 600;
    src: url('https://cdn.mmsrv.de/fonts/BarlowCondensed/SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Barlow Condensed';
    font-display: swap;
    font-weight: 700;
    src: url('https://cdn.mmsrv.de/fonts/BarlowCondensed/Bold.ttf') format('truetype');
}
