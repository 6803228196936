.ads {
    display: flex;
    flex-wrap: wrap;
    margin: (-@spacing * 4.5) (-@spacing / 2) 0;
}
.ads__no-entries {
    margin: (@spacing * 3) (@spacing / 2) (@spacing * 1.5);
}
.ad {
    &.ad--list {
        width: 30%;
        margin-top: (@spacing * 4.5);
        min-width: 300px;
        flex-grow: 1;
        border-left: (@spacing / 2) solid transparent;
        border-right: (@spacing / 2) solid transparent;
        @media @uxsm {
            min-width: 325px;
        }
        @media @twoCol {
            max-width: 50%;
        }
        @media @threeCol {
            max-width: 33.3%;
        }
    }
    &.ad--show {
        width: 100%;
    }
}
.ad__headline {
    margin: 0;
}
.ad__characteristics {
    margin-top: (@spacing * 2.5);
    font-family: Barlow Condensed, Arial, Helvetica, sans-serif;
}
.ad__details {
    width: 100%;
    border: none;
    border-spacing: 0;
}
.ad__details__row {
    &:first-of-type {
        padding-right: @spacing;
    }
}
.ad__features {
    list-style: none;
    margin: 0;
    padding: 0;
}
.ad__feature {
    display: inline;
}
.ad__description {
    margin: 0;
}
.ad__header {
    display: block;
    overflow: hidden;
    height: 0;
    padding-bottom: 66%;
}
.ad__image {
    display: block;
}
.ad__body {
    padding: 0 @spacing;
    top: (-@spacing);
    border-bottom: 6px solid @red;
}
.ad__short-description {
    font-family: Barlow Condensed, Arial, Helvetica, sans-serif;
    font-weight: 400;
    background: @white;
    padding: 10px;
}
.ad__title {
    font-family: Barlow Semi Condensed, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.4em;
    margin-top: 0;
    margin-bottom: (@spacing / 4);
    height: 2.8em;
    overflow: hidden;
}
.ad__detail-wrap {
    height: 2.8em;
    @media @uxl {
        height: unset;
    }
}
.ad__detail {
    white-space: nowrap;
}
.ad__price {
    position: relative;
    z-index: 1;
    &.ad__price--gross {
        display: block;
        font-size: 1.5em;
        line-height: 1.4em;
        margin-top: (@spacing / 2);
        font-family: Barlow Semi Condensed, Arial, Helvetica, sans-serif;
    }
    &.ad__price--net {
        display: block;
        @media @usm {
            display: inline-block;
        }
        @media @ocustom {
            display: block;
        }
    }
    &.ad__price--show {
        @media @usm {
            display: inline-block;
            margin-right: @spacing;
        }
    }
}
.ad__seperator {
    margin: 0 (@spacing / 4);
}
.ad__link {
    position: absolute;
    bottom: 0;
    right: (-@spacing);
}
.ad__arrow {
    display: block;
}