.form-group {
    display: flex;
    margin-bottom: @spacing;
    align-items: center;
}
.control-label {
    min-width: 200px;
}
.input {
    width: 100%;
    input, textarea {
        width: 100%;
        appearance: none;
        border: 2px solid @red;
        padding: (@spacing / 2);
    }
    textarea {
        resize: none;
        height: calc(5 * 1.2em);
    }
}