.preview-box {
    margin-bottom: (@spacing * 2.5);
    display: flex;
    flex-direction: column;
    @media @umd {
        flex-direction: row;
        &.preview-box--reverse {
            flex-direction: row-reverse;
        }
    }
}
.preview-box__header, .preview-box__body {
    display: flex;
    align-items: center;
    @media @umd {
        width: 50%;
    }
}
.preview-box__body {
    padding: 0 @spacing;
    margin-top: (-@spacing);
    border-bottom: (@spacing / 4) solid @red;
    @media @umd {
        padding: 0;
        margin-top: 0;
        border-bottom: none;
    }
}
.preview-box__content {
    width: calc(100% + @spacing);
    height: auto;
    background: @white;
    padding: @spacing @spacing (@spacing * 0.75);
    @media @umd {
        padding: (@spacing * 0.75) @spacing;
        margin-left: (-@spacing);
        border-bottom: (@spacing / 4) solid @red;
        &.preview-box__content--left {
            margin-left: 0;
            margin-right: (-@spacing);
        }
    }
}
.preview-box__logo {
    width: auto;
    max-width: 300px;
}
.preview-box__headline {
    margin-top: 0;
}
.preview-box__link {
    display: flex;
    position: relative;
    margin: (-@spacing);
    margin-right: (-@spacing * 2);
    justify-content: flex-end;
    @media @umd {
        justify-content: unset;
        &.preview-box__link--right {
            margin-right: (-@spacing);
            justify-content: flex-end;
        }
    }
}
.preview-box__more-icon {
    width: auto;
    display: block;
    transform: scaleX(1);
    @media @umd {
        &.preview-box__more-icon--mirror {
            transform: scaleX(-1);
        }
    }
}
.preview-box__more-text {
    position: absolute;
    color: @white;
    bottom: (@spacing * 0.75);
    right: @spacing;
    @media @umd {
        &.preview-box__more-text--left {
            left: @spacing;
        }
    }
}