.slick-disabled {
    display: none !important;
}
.slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(360deg);
    z-index: 2;
    width: 0;
    height: 0;
    border-style: solid;
    -webkit-appearance: none;
    padding: 0;
    background: none;
}
.slick-next {
    right: @spacing;

    border-width: (@spacing / 2 + @spacing / 4) 0 (@spacing / 2 + @spacing / 4) (@spacing + @spacing / 4);
    border-color: transparent transparent transparent #ffffff;
}
.slick-prev {
    left: @spacing;
    
    border-width: (@spacing / 2 + @spacing / 4) (@spacing + @spacing / 4) (@spacing / 2 + @spacing / 4) 0;
    border-color: transparent #ffffff transparent transparent;    
}
.slick-dots {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    bottom: @spacing;
    left: 50%;
    transform: translateX(-50%);
    li {
        display: inline-block;
        margin: 0 (@spacing / 4);
        button {
            -webkit-appearance: none;
            color: transparent;
            background: white;
            border: 5px solid white;
            padding: 0;
            font-size: 20px;
            width: 20px;
            height: 20px;

        }
        &.slick-active {
            button {
                background: black;
            }
        }
    }
}