.filters {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin-bottom: (@spacing * 2.5);
    z-index: 1;
    @media @ulg {
        flex-direction: row;
    }
}
.filter {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    margin-bottom: (@spacing / 2);
    @media @usm {
        align-items: center;
        flex-direction: row;
        margin-right: (@spacing * 2);
    }
    @media @uxxl {
        margin-bottom: 0;
    }
    &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
    }
}
.filter__name {
    width: 110px;
}