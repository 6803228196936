.mmpage-slider {
    max-width: 640px;
    &.mmpage-slider--full-width {
        max-width: 100%;
    }
}
.mmpage-slider__item {
    width: calc(100% - (@spacing * 1.5 - 6px));
    border-right: 6px solid @red;
    height: 0;
    padding-bottom: 73%;
    overflow: hidden;
    background: @light-grey;
}
.mmpage-slider__image {
    position: absolute;
    display: block;
    transform: translateX(-50%);
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    margin: 0 auto;
    background: url("../icons/tail-spin.svg") 50% no-repeat;
}
.mmpage-slider__btn {
    width: (@spacing * 1.5);
    height: (@spacing * 1.5);
    border: none;
    appearance: none;
    background: @red;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px;
    cursor: pointer;
    &.mmpage-slider__btn--prev {
        bottom: (@spacing * 1.5);
        transform: rotate(180deg);
    }
}
.mmpage-slider__icon {
    display: block;
    height: 100%;
    width: auto;
    margin: 0 auto;
}

.mmmobile-slider {
    max-width: 640px;
}
.mmmobile-slider__item {
    width: calc(100% - (@spacing * 1.5 - 6px));
    border-right: 6px solid @red;
    height: 0;
    padding-bottom: 73%;
    overflow: hidden;
    background: @light-grey;
}
.mmmobile-slider__image {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    margin: 0 auto;
    background: url("../icons/tail-spin.svg") 50% no-repeat;
}
.mmmobile-slider__btn {
    width: (@spacing * 1.5);
    height: (@spacing * 1.5);
    border: none;
    appearance: none;
    background: @red;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px;
    cursor: pointer;
    &.mmmobile-slider__btn--prev {
        bottom: (@spacing * 1.5);
        transform: rotate(180deg);
    }
}
.mmmobile-slider__icon {
    display: block;
    height: 100%;
    width: auto;
    margin: 0 auto;
}