.header {
    display: flex;
    align-items: flex-end;
    margin-top: 40px;
}
.header__slogan {
    display: none;
    width: 25%;
    margin-bottom: 8px;
    @media @umd {
        display: block;
    }
}
.header__corner {
    position: absolute;
    right: -225px;
    top: -225px;
    width: 850px;
    @media @ulg {
        right: -175px;
    }
}
.header__text {
    position: relative;
    display: block;
    color: @white;
    font-family: Barlow Condensed, Arial, Helvetica, sans-serif;
    font-size: 1.6em;
    line-height: 1.4em;
    font-weight: 700;
    text-transform: uppercase;
}
.header__menus {
    margin-left: auto;
    margin-right: (@spacing * 1.5);
    @media @umd {
        margin-left: 5%;
        margin-right: 0;
    }
}
.header__spacer {
    // display: none;
    // @media @umd {
        flex-grow: 1;
        display: block;
    // }
}
.header__mobile-menu {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    left: 0;
    background: @white;
    z-index: 2;
    // @media @uxxl {
        display: none;
    // }
}