.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    &.loader--small {
        display: inline-block;
        height: auto;
    }
}
.loader__icon {
    height: 150px;
    width: auto;
    &.loader__icon--small {
        height: .85em;
        margin-left: (@spacing / 2);
    }
}