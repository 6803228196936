.medienmonster {
    display: block;
    left: @spacing;
    bottom: (@spacing * 0.75);
    font-size: .8em;
    position: absolute;
    @media @ulg {
        left: unset;
        right: (@spacing * 1.25);
    }
}
.medienmonster__link {
    display: block;
    font-size: 1em;
    text-decoration: none;
    color: @black;
    @media @ufooter {
        color: @white;
    }
}
.medienmonster__logo {
    display: block;
    width: auto;
    height: 0.8em;
}