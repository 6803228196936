.mm-page__map {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: @spacing;
    &.mm-page__map--visible {
        height: 30vh;
    }
}
.mm-page__map__button {
    margin: auto;
}
.mm-mape__map__info {
    margin: auto;
    margin-top: @spacing;
}
.mm-page__map__iframe {
    width: 100%;
    height: 100%;
}