.footer {
    background: @light-grey;
    margin-top: (@spacing * 12);
}
.footer__content {
    max-width: @grid-width;
    width: 100%;
    margin: 0 auto;
    padding: (@spacing * 2.5) @spacing (@spacing * 3.75);
    @media @ulg {
        padding-bottom: (@spacing * 2.5);
    }
    p {
        margin: 0;
    }
}
.footer__corner {
    display: none;
    @media @ufooter {
        display: block;
    }
    position: absolute;
    width: auto;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
}
.footer__triangle {
    display: block;
    transition: transform 300ms ease;
    transform: translateX(335px);
    @media @uxxl {
        transform: translateX(200px);
    }
    @media @uxxxl {
        transform: translateX(0);
    }
}