.burger {
    display: block;
    cursor: pointer;
    transition: transform .5s ease;
    margin-bottom: 19px;
    @media @uxxl {
        display: none;
    }
}
.burger__line {
    display: block;
    background: @black;
    width: 40px;
    height: 6px;
    margin-bottom: 4px;
    transition: transform .5s ease;
    &:nth-child(1) {
        transform-origin: 10% 0;
    }
    &:nth-child(3) {
        transform-origin: 10% 100%;
        margin-bottom: 0;
    }
}