.mm-ani {
    transition: all .7s ease-in-out;
}
.mm-ani__open {
    opacity: 1;
    max-height: 0px;
    &.hide {
        height: auto;
        max-height: 0px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        display: block;
    }
}
.mm-ani__fadein {
    opacity: 1;
    transform: translateY(0px);
    &.hide {
        display: block;
        opacity: 0;
        transform: translateY(30px);
        visibility: hidden;
    }
}