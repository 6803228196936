.btn {
    display: inline-block;
    padding: (@spacing / 4) (@spacing / 2);
    text-decoration: none;
    cursor: pointer;
    background: @white;
    &.btn--default {
        border: 2px solid @red;
        color: @red;
    }
    &.btn--disabled {
        cursor: wait;
    }
    &.btn--buy {
        font-family: Barlow Semi Condensed, Arial, Helvetica, sans-serif;
        margin-top: (@spacing / 2);
        @media @usm {
            margin-top: 0;
            margin-left: @spacing;
        }
        @media @ocustom {
            margin-top: (@spacing / 2);
            margin-left: 0;
        }
    }
}