.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-@spacing / 2) (@spacing * 2);
    &.row--footer {
        @media @dmd {
            flex-direction: column;
        }
    }
}
.col {
    position: relative;
    vertical-align: top;
    padding: 0 (@spacing / 2);
    margin-top: 0;
    margin-bottom: (@spacing * 2);
    &.col--12 {
        flex-basis: 100%;
        max-width: 100%;
    }
    &.col--9 {
        flex-basis: 100%;
        max-width: 100%;
        @media @ulg {
            flex-basis: 75%;
            max-width: 75%;
        }
    }
    &.col--8 {
        flex-basis: 100%;
        max-width: 100%;
        @media @umd {
            flex-basis: 50%;
            max-width: 50%;
        }
        @media @ulg {
            flex-basis: 66.6%;
            max-width: 66.6%;
        }
    }
    &.col--6 {
        flex-basis: 100%;
        max-width: 100%;
        @media @umd {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    &.col--4 {
        flex-basis: 100%;
        max-width: 100%;
        @media @umd {
            flex-basis: 50%;
            max-width: 50%;
        }
        @media @ulg {
            flex-basis: 33.3%;
            max-width: 33.3%;
        }
    }
    &.col--3 {
        flex-basis: 100%;
        max-width: 100%;
        @media @ulg {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
    &.col--footer {
        @media @dmd {
            margin-bottom: @spacing;
        }
    }
    &.col--first-dmd {
        @media @dmd {
            order: -1;
        }
    }
}